import { Button } from '@arckit/ui'
import { Col, Row } from 'components/grid'
import { H3 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import { Link } from 'gatsby'
import React from 'react'

interface Props {
	image: string
	title: string
	text: string
	linkTo?: string
	buttonText?: string
	flip?: boolean
}

const BookCollection = ({ image, title, text, linkTo, buttonText, flip }: Props) => {
	return (
		<Row className={`mt-10 items-center ${flip && 'flex-row md:flex-row-reverse'}`}>
			<Col width="w-full md:w-7/12" className="mb-6 flex justify-center md:mb-0">
				<img src={image} alt={title} />
			</Col>
			<Col width="w-full md:w-5/12 mb-6 md:mb-0">
				<H3 className="mb-1">{title}</H3>
				<P>{text}</P>
				{linkTo && buttonText && (
					<div className="mt-4">
						<Link to={linkTo}>
							<Button label={buttonText} />
						</Link>
					</div>
				)}
			</Col>
		</Row>
	)
}

export default BookCollection
