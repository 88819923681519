import LargeForm from 'components/forms/LargeForm'
import { Button } from '@arckit/ui'
import { Col, Row, Section } from 'components/grid'
import OverlayVideo from 'components/overlay-video'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import BookCollection from '../components/arc-press/BookCollection'
import SectionTitle from '../components/global/SectionTitle'
import Layout from '../components/Layout'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import Title from 'components/typography/Title'

export default function ARCPressPage() {
	return (
		<Layout pageName="ARC Press">
			<Section>
				<Row>
					<div className="absolute top-0 left-0 z-auto h-full w-full bg-cover bg-no-repeat opacity-90" style={{ backgroundImage: 'url(/images/arc-core/books-bg.jpg)' }}>
						<div className="white-transparent-gradient-strong h-full w-full"></div>
					</div>
				</Row>
				<Row center className="relative z-auto mt-10">
					<Col width="w-full" className="text-center">
						<StaticImage src="../../static/images/arc-press/if-the-shoe-fits-spread.png" alt="if the shoe fits cover spread" height={400} />

						<H1 className="mb-3">ARC Press</H1>
						<P className="mx-auto max-w-4xl">
							With more than 900 books written in English and Spanish, ARC Press offers students an abundance of choice. ARC Press books are designed for beginning
							readers and illustrated with bold, engaging images. Regardless of age or first language, ARC Press books bridge the gap between listening to books read
							by others and reading independently.
						</P>
					</Col>
				</Row>
			</Section>

			<Section className="mt-10 md:mt-20">
				<BookCollection
					image="/images/arc-press/student-author-book-stack.png"
					title="Student–Created Titles"
					text="Students who write and illustrate their own books during American Reading Company’s Research Labs have the opportunity to get their book published by ARC Press."
					linkTo="/student-books"
					buttonText="See All Student Books"
					flip
				/>
				<BookCollection
					image="/images/arc-press/fstk-book-stack.png"
					title="Books to Teach Specific Foundational Skills"
					text="We provide students with authentic texts to practice specific decoding skills, word work, and word recognition."
					buttonText="Learn about FSTKs"
				/>
				<BookCollection
					image="/images/arc-press/high-interest-book-stack.png"
					title="High-Interest Nonfiction Texts"
					text="ARC Press helps students read like scientists and historians as they explore the world around them. Our Science and Social Studies books teach grade level concepts and reading skills side by side."
					flip
				/>
				<BookCollection
					image="/images/arc-press/fiction-book-stack.png"
					title="Fun and Engaging Fiction"
					text="Students can follow the adventures of favorite characters and grow their reading skills at the same time."
					linkTo="#"
					buttonText=""
				/>
				<BookCollection
					image="/images/arc-press/spanish-book-stack.png"
					title="Authentic Emergent Reader Texts in Spanish"
					text="ARC Press en español has published over 590 unique titles for beginning readers, 24 of which have been selected as exemplar texts for the Common Core en español initiative."
					buttonText="See Exemplar Texts"
					flip
				/>
				<Row center className="mt-8">
					<Col>
						<div className="flex flex-wrap items-center justify-center gap-6 rounded bg-slate-100 p-6">
							<div className="flex w-full justify-center md:w-1/3">
								<img src="/images/arc-press/premio-campoy-ada-logo.png" alt="premio campoy ada logo" />
							</div>
							<div className="w-full text-center md:w-2/3">
								<Title>Recognition from Premio Campoy-Ada</Title>
								<P size="md">
									Since its inception in 2017, the Premio Campoy-Ada from the Academia Norteamericana de la Lengua Española (ANLE) has recognized the ARC Press en
									español publications with 18 awards, including three first place awards in 2023.
								</P>
								<div className="mt-6 flex justify-center">
									<a href="https://arcloves.com/sm4igr">
										<Button>Watch the Video</Button>
									</a>
								</div>
							</div>
						</div>
					</Col>
				</Row>
				<Row className="mb-32 mt-20">
					<Col width="w-full md:w-1/2" className="mb-6 md:mb-0">
						<OverlayVideo title="Authentic Spanish Libraries" />

						<P shade="darker" size="sm" className="mx-auto mt-3 w-full text-center sm:w-5/6">
							Content Developer Genesis Pimentel discusses the power of transcreation in developing meaningful Spanish–language texts for ARC Press.
						</P>
					</Col>
					<Col width="w-full md:w-1/2" className="mb-6 md:mb-0">
						<OverlayVideo title="Peekskill Educators are Building Knowledge in Two Languages" />

						<P shade="darker" size="sm" className="mx-auto mt-3 w-full text-center sm:w-5/6">
							The students of Peekskill City School District are building knowledge and making cross-linguistic connections in ARC Core's English– and
							Spanish–language thematic Units.
						</P>
					</Col>
				</Row>
				{/* <BookCollection
					image="/images/arc-press/diverse-book-stack.png"
					title="Training Wheels Series"
					text='The Training Wheels Series takes your child from "Can&apos;t Read Yet" to "Can Read."'
					buttonText="See Training Wheels Series"
				/> */}
			</Section>
			<Section margin="mt-10 md:mt-20">
				<SectionTitle
					title="Our Authors"
					text="Our multilingual ARC Press team features well-known authors in children’s publishing, talented illustrators and designers, and amazing storytellers."
				/>
				<Row center>
					<Col width="w-full md:w-3/4" className="mb-6 md:mb-0">
						<OverlayVideo title="Drawn by Hand" />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20 md:mt-40">
				<Row middle>
					<Col width="w-full md:w-1/2" gutter="2xl">
						<Title className="mb-2">Our Printing Partner</Title>
						<P size="md" className="mb-3">
							Since 2009, we have partnered with AZ Litho to print ARC Press books and ARC curriculum materials. AZ Litho uses 100% renewable energy sources to
							conserve natural resources and reduce emissions. They are also a triple bottom line organization that is certified by the EPA as a green company.
						</P>
						<P size="md" className="mb-3">
							During our partnership, we have printed more than 10 million books. From 2013 to 2022, this eco-friendly venture has resulted in:
						</P>
						<ul className="list-checkmark mt-6 pl-10 text-lg text-gray-500">
							<li className="mb-3">1,390,547 lbs. of air emissions saved</li>
							<li className="mb-3">1,355 barrels of natural gas saved</li>
							<li className="mb-3">86,608 trees planted</li>
						</ul>
					</Col>
					<Col width="w-full md:w-1/2" gutter="2xl" className="bg-slate-100 py-12 pr-8 md:pr-12">
						<StaticImage src="../../static/images/arc-press/az-litho.png" alt="az litho" className="ml-0 md:-ml-12" />
					</Col>
				</Row>
			</Section>

			<Section margin="mt-20">
				<Row center className="pb-6">
					<Col width="lg:w-1/2">
						<LargeForm pageName="ARC Press" />
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}
